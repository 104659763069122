.c-text {
    $c: &;

    @apply .text-base;

    p {
        @apply .mb-8;

        &:last-child {
            @apply .mb-0;
        }
    }

    ul {
        @apply .mb-8;

        li {
            @apply .relative .pl-4;

            &:before {
                content: '•';

                @apply .absolute .left-0 .top-0 .text-grey .text-2xl .leading-none;
            }
        }

        &:last-child {
            @apply .mb-0;
        }
    }

    a {
        @apply .text-secondary .leading-none .font-bold .border-b .border-secondary;

        &:hover,
        &:active {
            @apply .text-tertiary .border-tertiary;
        }
    }

    strong,
    b {
        @apply .font-bold;
    }

    h2 {
        @apply .mb-2;
    }

    h3 {
        @apply .mb-1.5;
    }

    &--usp-list {
        ul {
            @apply .-mb-3;

            li {
                @apply .pb-3 .pl-0;

                &:before {
                    @apply .hidden;
                }
            }

            &:last-child {
                @apply .-mb-3;
            }
        }
    }
}

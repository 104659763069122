.c-icon {
    @apply .fill-current .transition-all;

    &--arrow {
        width: 4px;
        height: 7px;
    }

    &--arrow-large {
        width: 7px;
        height: 12px;
    }

    &--arrow-alt {
        width: 3.125px;
        height: 5px;
    }

    &--facebook {
        width: 8px;
        height: 16px;
    }

    &--marker {
        width: 12px;
        height: 15px;
    }

    &--construction {
        width: 21px;
        height: 12px;
    }

    &--close {
        width: 8px;
        height: 8px;
    }

    &--check {
        width: 14px;
        height: 12px;
    }

    &--download {
        width: 17px;
        height: 16px;
    }
}

// -----------------------------------------------------------------------------
// elements/heading
// -----------------------------------------------------------------------------

h1,
h2,
h3,
h4,
h5 {
    @apply .font-primary .leading-tight .text-tertiary;
}

h1,
.h1 {
    @apply .text-2xl .font-bold;

    @screen lg {
        @apply .text-3.5xl;
    }
}

h2,
.h2 {
    @apply .text-2.5xl;
}

h3,
.h3 {
    @apply .text-xl .font-bold .leading-snug;
}

h4,
.h4 {
    @apply .text-lg;
}

h5,
.h5 {
    @apply .text-base;
}

.c-nav {
    $c: &;

    @apply .text-0;

    &__list {
    }

    &__item {
    }

    &__link {
    }

    &__label {
        @apply .inline-block .transition-all;
    }

    &--primary {
        #{$c} {
            &__list {
                @apply .-mb-4;

                @screen lg {
                    @apply .-ml-8 .mb-0;
                }

                @screen xl {
                    @apply .-ml-10;
                }
            }

            &__item {
                @apply .block .text-center .pb-4;

                @screen lg {
                    @apply .inline-block .pl-8 .text-left .pb-0;
                }

                @screen xl {
                    @apply .pl-10;
                }

                &.is-active {
                    #{$c} {
                        &__label {
                            @apply .text-secondary .border-secondary;
                        }
                    }
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-secondary .border-secondary;
                        }
                    }
                }
            }

            &__label {
                @apply .text-lg .text-tertiary .leading-tight .border-b .border-transparent;
            }
        }
    }

    &--secondary {
        #{$c} {
            &__list {
                @apply .-mb-4;

                @screen lg {
                    @apply .-ml-5 .mb-0;
                }
            }

            &__item {
                @apply .block .text-center .pb-4;

                @screen lg {
                    @apply .inline-block .pl-5 .text-left .pb-0;
                }

                &.is-active {
                    #{$c} {
                        &__label {
                            @apply .text-secondary;
                        }
                    }
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .text-secondary;
                        }
                    }
                }
            }

            &__label {
                @apply .text-tertiary .text-sm .leading-none;
            }
        }
    }

    &--breadcrumb {
        #{$c} {
            &__list {
                @apply .-ml-1.5 .-mb-1.5;

                @screen md {
                    @apply .flex .items-center;
                }
            }

            &__item {
                @apply .inline-block .pl-1.5 .pb-1.5;

                &:last-child {
                    #{$c} {
                        &__label {
                            @apply .text-secondary .font-normal .border-transparent;
                        }

                        &__icon {
                            @apply .hidden;
                        }
                    }
                }
            }

            &__link {
            }

            &__label {
                @apply .text-xs .leading-none .font-bold .text-tertiary .border-b .border-tertiary;
            }

            &__icon {
                @apply .text-tertiary .pl-1.5;
            }
        }
    }

    &--footer {
        #{$c} {
            &__list {
                @apply .-mb-1.5;
            }

            &__item {
                @apply .pb-1.5;
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .border-white-30;
                        }
                    }
                }
            }

            &__label {
                @apply .text-base .text-white .border-b .border-transparent .leading-none;
            }
        }
    }

    &--legal {
        #{$c} {
            &__list {
            }

            &__item {
                @apply .block .relative .pl-2 .mb-2;

                @screen md {
                    @apply .inline-block .ml-2 .mb-0;
                }

                &:before {
                    top: 50%;

                    content: '•';
                    transform: translate(-50%, -50%);

                    @apply .absolute .text-sm .left-0;
                }

                &:last-child {
                    @apply .mb-0;
                }
            }

            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label {
                            @apply .border-white;
                        }
                    }
                }
            }

            &__label {
                @apply .text-xs .text-white .border-b .border-white-30 .leading-none;
            }
        }
    }

    &--social {
        #{$c} {
            &__list {
                @apply .-ml-2;
            }

            &__item {
                @apply .inline-block .pl-2;
            }

            &__link {
                @apply .w-10 .h-10 .bg-white .flex .items-center .justify-center .text-secondary;

                &:hover,
                &:active {
                    @apply .bg-primary;

                    .c-icon {
                        @apply .text-white;
                    }
                }
            }
        }
    }
}

.c-label {
    $c: &;

    @apply .inline-block .uppercase .font-bold .text-sm .tracking-wider .px-3 py-2 .leading-none;

    &--primary {
        @apply .bg-primary .text-white;
    }

    &--secondary {
        @apply .bg-secondary .text-white;
    }

    &--white {
        @apply .bg-white .text-secondary;
    }

    &--xs {
        @apply .text-xs .px-1 py-1;
    }
}

.c-project-detail {
    $c: &;

    &__info-list {
        @apply .text-0 .mb-4;

        @screen lg {
            @apply .flex .items-center .mb-0;
        }

        li {
            @apply .relative .leading-none .pb-4;

            @screen lg {
                @apply .pl-6 .ml-6 .pb-0;
            }

            &:before {
                @screen lg {
                    top: 50%;

                    content: '';
                    transform: translate(-50%, -50%);

                    @apply .absolute .left-0 .bg-grey-lightest .w-px .h-5;
                }
            }

            &:first-child {
                @apply .pl-0 .ml-0;

                &:before {
                    @apply .hidden;
                }
            }
        }
    }
}

.c-info-window {
    max-width: 240px;

    $c: &;

    @apply .relative .font-primary .pt-5 .pr-8 .pb-6 .pl-4.5 .text-0;

    &__title {
        @apply .text-base;
    }

    &__body {
        @apply .mt-3 .text-tertiary;

        ul {
            @apply .-mb-1;

            li {
                @apply .leading-none .pb-1 .text-0;
            }

            span {
                @apply .text-base;
            }
        }
    }

    &__footer {
        @apply .mt-3;
    }

    &__close {

        @apply .absolute .right-0 .top-0 .text-tertiary .pr-3 .pt-3;
    }
}

.c-header {
    $c: &;

    @apply .text-0 .border-t-3 .border-secondary .pb-10;

    &__top {
        @apply .relative .mb-3.5 .bg-grey-light .h-10;

        @screen lg {
            @apply .h-auto;
        }
    }

    &__top-inner {
        @apply .relative;

        &:before {
            right: 100%;

            content: '';

            @apply .absolute .top-0 .bottom-0 .w-full .bg-white .hidden;

            @screen lg {
                @apply .block;
            }
        }
    }

    &__bottom {
    }
}

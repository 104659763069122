.c-notification {
    max-height: 0;

    $c: &;

    @apply .bg-secondary .text-0 .transition-all;

    &__inner {
        @apply .py-8;
    }

    &__text {
        @apply .text-white .text-sm;
    }

    &__dismiss {
    }

    &.is-open {
        max-height: 600px;
    }
}

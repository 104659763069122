.c-gallery {
    $c: &;

    @apply .relative .text-0;

    &__item {
    }

    &__link {
        @apply .relative .w-full .h-full;
    }

    &__thumbs {
        #{$c} {
            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__overlay {
                            @apply .opacity-100;
                        }
                    }
                }
            }
        }
    }

    &__overlay {
        @apply .absolute .w-full .h-full .flex .items-center .justify-center .text-base .text-white .bg-tertiary-30 .font-bold .opacity-0 .transition-all;

        &.is-active {
            @apply .opacity-100;
        }
    }

    &__indicator {
        @apply .absolute .bottom-0 right-0 .bg-secondary .text-white .w-8 .h-8 .flex .items-center .justify-center .font-bold .text-2xs;
    }

    &--project-detail {
        height: 200px;

        @screen md {
            height: 540px;
        }
    }
}

.c-content {
    $c: &;

    @apply .pb-20;

    &__section {
        @apply .relative;

        &.has-bg {
            @apply .pb-20;
            
            &:before {
                top: 128px;

                content: '';

                @apply .absolute .bg-grey-light .left-0 .right-0 .bottom-0;
            }
        }
    }
}

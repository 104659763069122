.c-card {
    $c: &;

    @apply .text-0 .flex .flex-col .w-full .h-full .bg-white .transition-all;

    &__label {
        top: 20px;
        right: 20px;

        @apply .absolute;
    }

    &__body {
        @apply .relative .h-full .px-8 .pt-6;
    }

    &__status {
        bottom: 100%;

        @apply .absolute .bg-white .text-secondary .text-sm;
    }

    &__link {
        @apply .inline-block .text-sm .font-bold .uppercase .tracking-wider .text-secondary .pb-3.5 .border-b .border-secondary .transition-all .leading-none;
    }

    &:hover,
    &:active {
        box-shadow: 0 2px 14px 0 theme('colors.shadow');

        #{$c} {
            &__link {
                @apply .text-primary .border-primary;
            }
        }
    }

    &--infoAanvraag {
        @apply .bg-grey-light .h-auto;

        #{$c} {
            &__body {
                @apply .pb-6;
            }
        }
    }

    &.is-landscape {
        @screen xl {
            @apply .flex-row;
        }

        #{$c} {
            &__figure {
                @screen xl {
                    @apply .w-2/5;
                }
            }

            &__body {
                @apply .border .border-grey-light;

                @screen xl {
                    @apply .w-3/5 .border-l-0;
                }
            }
        }
    }
}

.c-project-unit-table {
    $c: &;

    @apply .text-0 .border .border-grey-lightest;

    &__rows {
        &--header {
            @apply .bg-grey-light;

            #{$c} {
                &__column {
                    @apply .py-3.75 .leading-none .font-bold .text-tertiary;
                }
            }
        }

        &--body {
            #{$c} {
                &__row {
                    &:nth-child(even) {
                        @apply .bg-grey-lighter;
                    }

                    &:last-child {
                        @apply .border-b-0;
                    }
                }
            }
        }
    }

    &__row {
        @apply .flex .border-b .border-grey-lightest;

        &--sold {
            #{$c} {
                &__column {
                    &:after {
                        content: '';

                        background-color: rgba(#fff, .7);

                        @apply .absolute .top-0 .right-0 .bottom-0 .left-0;
                    }

                    &--prijs-excl,
                    &--prijs-incl {
                        #{$c} {
                            &__content {
                                @apply .z-50 .text-red .font-bold;
                            }
                        }
                    }
                }
            }
        }
    }

    &__column {
        @apply .relative .px-5 .py-3.75 .text-base .leading-normal .border-l .border-grey-lightest;

        &--title {
            width: 16%;
        }

        &--type,
        &--slaapkamers {
            width: 12%;
        }

        &--oppervlakte {
            width: 8%;
        }

        &--prijs-excl,
        &--prijs-incl {
            width: 14%;
        }

        &--plannen {
            width: 26%;
        }

        &:first-child {
            @apply .border-l-0;
        }
    }

    &__content {
        @apply .relative;
    }
}

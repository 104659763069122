.c-link {
    $c: &;

    @apply .inline-flex .text-0 .items-center;

    &__label {
        @apply .inline-block .text-base .leading-none .font-bold .font-primary .transition-all;
    }

    &--primary {
        #{$c} {
            &__icon {
                @apply .text-secondary .inline-block .mr-2;
            }

            &__label {
                @apply .text-secondary .border-b .border-secondary;
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__icon {
                    .c-icon {
                        @apply .text-tertiary;
                    }
                }

                &__label {
                    @apply .text-tertiary .border-tertiary;
                }
            }
        }

        &.is-active {
            #{$c} {
                &__label {
                    @apply .text-tertiary .border-tertiary;
                }
            }
        }
    }

    &--secondary {
        #{$c} {
            &__label {
                @apply .text-tertiary .border-b .border-tertiary-30;
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__label {
                    @apply .text-tertiary .border-tertiary;
                }
            }
        }
    }

    &--sm {
        #{$c} {
            &__label {
                @apply .text-sm;
            }
        }
    }

    &.no-border {
        #{$c} {
            &__label {
                @apply .border-b-0;
            }
        }
    }
}

.c-widget {
    box-shadow: 0 2px 14px 0 theme('colors.shadow');

    $c: &;;

    @apply .py-10 .px-8 .bg-white .border-t-5 .border-primary;

    &__title {
        @apply .mb-5 .text-lg;
    }

    &__text {
        @apply .mb-5;
    }
}

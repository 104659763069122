.c-mobile-nav {
    right: -100%;

    opacity: 1;

    $c: &;

    @apply .fixed .flex .bg-grey-light .z-20 .transition-all .px-10 .py-24 .w-full .h-full .top-0 .border-t-3 .border-secondary .items-center .justify-center;

    &.is-active {
        @apply .transition-all .right-0;
    }
}

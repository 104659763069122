.c-button {
    $c: &;

    @apply .text-0 .inline-flex .overflow-hidden .items-stretch;

    &__label {
        @apply .flex-grow .relative .inline-block .px-4.5 .py-3.75 .transition-all;

        &:after {
            content: '';
            transform: translateX(100%);

            @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .transition-all;
        }
    }

    &__label-text {
        @apply .font-bold .z-10 .relative .transition-all;
    }

    &__icon {
        @apply .flex-shrink-0 .relative .px-4 .flex .items-center;
    }

    &:hover,
    &:active {
        #{$c} {
            &__label {
                &:after {
                    transform: translateX(0);
                }
            }
        }
    }

    &--primary {
        #{$c} {
            &__label {
                @apply .bg-primary;

                &:after {
                    @apply .bg-primary-dark;
                }
            }

            &__label-text {
                @apply .text-base .leading-tight .text-white;
            }

            &__icon {
                @apply .bg-primary-dark .text-white;
            }
        }
    }

    &--secondary {
        #{$c} {
            &__label {
                @apply .bg-secondary;

                &:after {
                    @apply .bg-secondary-dark;
                }
            }

            &__label-text {
                @apply .text-sm .leading-snug .text-white .uppercase .tracking-wider;
            }

            &__icon {
                @apply .bg-secondary-dark .text-white;
            }
        }
    }

    &--tertiary {
        #{$c} {
            &__label {
                @apply .border .border-white .px-6 .py-3.75;
            }

            &__label-text {
                @apply .text-sm .text-white .leading-none;
            }
        }

        &:hover,
        &:active {
            #{$c} {
                &__label {
                    @apply .bg-white;
                }

                &__label-text {
                    @apply .text-grey;
                }
            }
        }
    }

    &--sm {
        #{$c} {
            &__label {
                @apply .px-4 .py-3;
            }
        }
    }

    &--xs {
        #{$c} {
            &__label {
                @apply .px-3.5 .py-2;
            }
        }
    }

    &--xl {
        #{$c} {
            &__label {
                @screen lg {
                    @apply .px-8 .py-5;
                }
            }

            &__label-text {
                @screen lg {
                    @apply .text-lg .leading-normal;
                }
            }

            &__icon {
                @screen lg {
                    @apply .px-7;
                }
            }
        }
    }
}

.c-content-builder {
    $c: &;

    &__list {
    }

    &__item {
        @apply .mb-8;

        &:last-child {
            @apply .mb-0;
        }
    }
}

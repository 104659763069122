.c-form {
    $c: &;

    @apply .text-0;

    &__rows {
        @apply .-mb-4;

        @screen xl {
            @apply .-mb-2;
        }
    }

    &__row {
        @apply .pb-4;

        @screen xl {
            @apply .pb-2;
        }
    }

    &__columns {
        @apply .flex .flex-wrap .-ml-12 .-mb-4;

        @screen md {
            @apply .mb-0;
        }
    }

    &__column {
        @apply .w-full .pl-12 .pb-4;

        @screen md {
            @apply .flex-1 .w-auto .pb-0;
        }
    }
}

.c-cookie-consent {
    position: fixed;
    z-index: 9999;
    right: 0;
    bottom: 0;
    left: 0;

    transform: translateY(100%);

    $c: &;

    @apply .bg-grey .p-4 .transition-all;

    &__content {
        @screen md {
            @apply .flex .items-center;
        }
    }

    &__message {
        @apply .text-sm .leading-normal .mb-4 .text-white .flex-grow;

        @screen md {
            @apply .mr-4 .mb-0;
        }

        a {
            @apply .text-white .border-b .border-white .leading-tight;

            &:hover,
            &:active {
                @apply .text-primary .border-primary;
            }
        }
    }

    &__dismiss {
        @apply .flex-shrink-0;
    }

    &.in-view {
        transform: translateY(0);
    }
}

.c-footer {
    $c: &;

    @apply .py-16 .bg-secondary-dark .text-white;

    h3 {
        @apply .text-white .mb-4;
    }

    &__top {
    }

    &__bottom {
        @apply .pt-8 .text-0;
    }
}

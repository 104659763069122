.c-form-field {
    $c: &;

    @apply .relative .h-full;

    &__wrapper {
        @screen xl {
            @apply .flex;
        }
    }

    &__label {
        min-width: 120px;

        @apply .relative .text-base .leading-none .text-tertiary .inline-block .pb-2;

        @screen xl {
            @apply .pt-3 .pr-3
        }

        &.is-required {
            &:after {
                content: '*';

                @apply .absolute .ml-1;
            }
        }
    }

    &__input {
        @apply .relative .text-base .text-tertiary .w-full .h-full .border .border-grey-lightest .bg-transparent .transition-all .py-3.5 .px-4;

        &:focus {
            @apply .border-grey;
        }
    }

    &__errors {
        @apply .text-0;
    }

    &.has-errors {
        #{$c} {
            &__input {
                @apply .border-error;
            }
        }
    }

    &--recaptcha{
        #{$c}__errors{
            @apply .text-base text-red;
        }
    }
}

@tailwind base;

@tailwind components;

/* purgecss start ignore */
@import "01-generics/_base.scss";
@import "02-vendors/_hamburgers.scss";
@import "02-vendors/_photoswipe.scss";
@import "02-vendors/_snazzy-info-window.scss";
@import "03-elements/_body.scss";
@import "03-elements/_button.scss";
@import "03-elements/_heading.scss";
@import "03-elements/_input.scss";
@import "03-elements/_link.scss";
@import "04-components/_button.scss";
@import "04-components/_card.scss";
@import "04-components/_container.scss";
@import "04-components/_content-builder.scss";
@import "04-components/_content.scss";
@import "04-components/_cookie-consent.scss";
@import "04-components/_figure.scss";
@import "04-components/_footer.scss";
@import "04-components/_form-field.scss";
@import "04-components/_form.scss";
@import "04-components/_gallery.scss";
@import "04-components/_header.scss";
@import "04-components/_hero.scss";
@import "04-components/_icon.scss";
@import "04-components/_info-window.scss";
@import "04-components/_label.scss";
@import "04-components/_link.scss";
@import "04-components/_mobile-nav.scss";
@import "04-components/_nav.scss";
@import "04-components/_notification.scss";
@import "04-components/_project-detail.scss";
@import "04-components/_project-unit-table.scss";
@import "04-components/_text.scss";
@import "04-components/_widget.scss";
/* purgecss end ignore */

@tailwind utilities;
.c-hero {
    $c: &;

    @apply .relative;

    &__button {
        @screen lg {
            transform: translate(0, -50%);

            @apply .pr-10;

            .c-button {
                &__label {
                    max-width: 244px;
                }
            }
        }
    }
}
